import React from "react";

import Layout from "../../components/layout";
import { CheckList, CheckListItem } from "../../components/CheckList";
import { CodePreview } from "../../components/docs/CodePreview";
import EmailTemplateSmallBox from "../../components/EmailTemplateSmallBox";

import organizationInviteEmailImage from "../../images/email-templates/email-template-organization-invite.jpg";
import organizationInviteEmailImageEsai from "../../images/email-templates/email-template-organization-invite-esai.jpg";
import organizationInviteEmailImageRobolly from "../../images/email-templates/email-template-organization-invite-robolly.jpg";
import organizationInviteEmailImageElephanty from "../../images/email-templates/email-template-organization-invite-elephanty.jpg";
import organizationInviteEmailImageDoggify from "../../images/email-templates/email-template-organization-invite-doggify.jpg";
import ssoEmailImage from "../../images/email-templates/email-template-sso.jpg";
import passwordResetEmailImage from "../../images/email-templates/email-template-password-reset.jpg";
import paymentFailedEmailImage from "../../images/email-templates/email-template-payment-failed.jpg";
import canceledsubscriptionEmailImage from "../../images/email-templates/email-template-canceled-subscription.jpg";
import welcomeEmailImage from "../../images/email-templates/email-template-welcome.jpg";
import invoiceEmailImage from "../../images/email-templates/email-template-invoice.jpg";

export default () => {
	return (
		<Layout
			pageMeta={{
				title:
					"Organization invite email template – Well-tested & Ready-to-use",
				description:
					"Send an organization invite email to help team members join your organization, set up their account, and start collaborating.",
			}}>
			<div>
				<section className="aboveFold mb-120">
					<div className="container">
						<div className="templates-header">
							<div className="text-left">
								<h1>Organization invite email template</h1>

								<p className="">
									Send an organization invite email to help team members join
									your organization, set up their account, and start
									collaborating. With Sidemail, you can customize and deliver
									organization invite emails in no time, empowering your team to
									work together effectively.
								</p>

								<CheckList className="mb-30">
									<CheckListItem>Mobile-responsive</CheckListItem>
									<CheckListItem>Supports all email clients</CheckListItem>
									<CheckListItem>White-label</CheckListItem>
									<CheckListItem>Fully customizable</CheckListItem>
								</CheckList>

								<a
									className="button button-primary mb-40"
									href="https://client.sidemail.io/register">
									Get this template
								</a>
							</div>

							<img
								src={organizationInviteEmailImage}
								loading="lazy"
								alt="Organization invite email template"
								className="templates-image"
							/>
						</div>
					</div>
				</section>

				<section className="container maxWidth-820 text-center mb-150">
					<h2 className="title-large">Ready to send via API</h2>

					<p className="maxWidth-680 text-large text-center itemCenter mb-50">
						Send emails, like this organization invite email template, from your
						application in just a few minutes — Sidemail makes it incredibly
						easy to create, deliver, maintain, and analyze beautiful
						transactional emails.{" "}
						<a href="/email-sending-api/">Learn more about email sending API</a>
						.
					</p>

					<CodePreview language="javascript">
						{`
await sidemail.sendEmail({
toAddress: "user@email.com",
fromName: "Company name",
fromAddress: "your@company.com",
templateName: "Organization invite",
templateProps: {
    "organization_name": "Doggify",
    "project_name": "Company name",
    "admin_email": "john.doe@company.com"
    },
});
`}
					</CodePreview>
				</section>

				<section className="container text-center mb-150">
					<h2 className="title-large">Customize design to fit your brand</h2>

					<p className="maxWidth-680 text-large text-center itemCenter mb-50">
						With Sidemail, you get a beautiful email design that you can fully
						customize to fit your brand — upload your logo, pick your brand
						colors and font, and if you fancy, turn on dark mode.
					</p>

					<div className="templatePreviewContainer-4">
						<div className="templatePreview-4">
							<img
								src={organizationInviteEmailImageEsai}
								alt="Organization invite email template image"
								loading="lazy"
							/>
						</div>
						<div className="templatePreview-4">
							<img
								src={organizationInviteEmailImageDoggify}
								alt="Organization invite email template image"
								loading="lazy"
							/>
						</div>
						<div className="templatePreview-4">
							<img
								src={organizationInviteEmailImageRobolly}
								alt="Organization invite email template image"
								loading="lazy"
							/>
						</div>
						<div className="templatePreview-4">
							<img
								src={organizationInviteEmailImageElephanty}
								alt="Organization invite email template image"
								loading="lazy"
							/>
						</div>
					</div>
				</section>

				<section className="mb-140">
					<div className="container maxWidth-880">
						<div className="grid grid--alignCenter mb-30">
							<div className="grid-cell grid-cell--notFluid">
								<h2 className="m-0">Common questions</h2>
							</div>

							<div className="grid-cell">
								<hr className="separator m-0" />
							</div>
						</div>

						<p className="u-text-bold mb-4">
							Do I need to work with HTML in order to use this organization
							invite email template?
						</p>
						<p className="text-light mt-0 mb-50">
							No, you don't. We provide you with email templates that are
							well-tested and ready to send, so you don't need to be familiar
							with coding HTML emails.
						</p>

						<p className="u-text-bold mb-4">
							May I customize the design of the organization invite email
							template?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, you can fully customize the design – upload your logo, pick
							colors and fonts, and turn dark mode on or off.
						</p>

						<p className="u-text-bold mb-4">
							Is this email template mobile responsive?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, all templates, including this organization invite email, are
							mobile responsive and well-tested, so they won't break in any
							inbox (including Outlook!).
						</p>

						<p className="u-text-bold mb-4">
							How long does it take to get started?
						</p>
						<p className="text-light mt-0 mb-50">
							Integrating transactional emails, like this organization invite
							email, is extremely fast and straightforward with Sidemail. The
							whole integration process takes under 30 minutes.{" "}
							<a href="https://client.sidemail.io/register">
								Start your free trial here
							</a>
							.
						</p>

						<p className="u-text-bold mb-4">
							May I create my own email template from scratch?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, Sidemail features{" "}
							<a href="/no-code-email-editor/"> No-code email editor</a> that
							will help you craft professionally-looking, responsive email
							templates. You can also use Sidemail's AI email generator to craft
							the email copy.
						</p>
					</div>
				</section>

				<section className="container maxWidth-880 text-center mb-50">
					<h2 className="title-large mb-50">More email templates...</h2>

					<div className="moreTemplatesWrapper">
						<EmailTemplateSmallBox
							title="Welcome email"
							href="/transactional-email-templates/welcome-email-template/"
							src={welcomeEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Password reset email"
							href="/transactional-email-templates/password-reset-email-template/"
							src={passwordResetEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Single sign-on email"
							href="/transactional-email-templates/sso-email-template/"
							src={ssoEmailImage}
						/>
					</div>

					<div className="moreTemplatesWrapper mb-40">
						<EmailTemplateSmallBox
							title="Invoice email"
							href="/transactional-email-templates/invoice-email-template/"
							src={invoiceEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Subscription payment failed"
							href="/transactional-email-templates/payment-failed-email-template/"
							src={paymentFailedEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Canceled subscription email"
							href="/transactional-email-templates/canceled-subscription-email-template/"
							src={canceledsubscriptionEmailImage}
						/>
					</div>

					<a href="/transactional-email-templates/">
						Browse all email templates →
					</a>
				</section>
			</div>
		</Layout>
	);
};
